import { Disclosure } from "@headlessui/react"
import Highlight from "../../freestanding/highlight"
import Button from "../next/button/button"
import faqData from "../../../../page-content/pricing-faq-data.json"

export function PricingFaq() {
  return (
    <div className="relative col-span-full mx-auto grid gap-y-8 place-self-center px-6 sm:max-w-[640px] md:max-w-[768px] md:px-12 lg:px-0">
      <div className="flex w-auto flex-col items-center gap-y-4 md:gap-y-6 lg:gap-x-8">
        <h2 className="text-center text-4xl font-medium  text-gray-900 md:text-5xl lg:max-w-lg lg:flex-1">
          <Highlight>FAQs</Highlight>
        </h2>
        <p className=" max-w-[50ch] text-center text-lg text-gray-600">
          <Highlight>
            We've compiled a list of frequently asked questions for your
            convenience. If you have other questions or concerns that are not on
            this list, don't hesitate to reach out. We're here to help and [we'd
            love to hear from you](https://www.ory.sh/talk-to-us).
          </Highlight>
        </p>
      </div>
      <div className="grid gap-8 lg:grid-flow-row lg:grid-cols-1">
        {faqData.faqs.map((faq, fgIndex) => (
          <Disclosure key={fgIndex} as={"div"} className="col-span-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full border-b border-gray-200 bg-white py-4">
                  <div className="flex w-full items-baseline justify-between">
                    <h3 className="text-left text-lg font-medium text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="text-indigo-600">{open ? "-" : "+"}</p>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="flex flex-col pt-8">
                    <div className="flex flex-row items-baseline border-t border-gray-100 text-base first:border-none">
                      <p className="text-gray-900">
                        <Highlight>{faq.answer}</Highlight>
                      </p>
                    </div>
                    {faq.image && (
                      <div className="mt-4 flex flex-row items-center text-base first:border-none">
                        <img src={faq.image} alt={faq.question} />
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  )
}
