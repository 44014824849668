import cn from "classnames"
import { ComponentProps } from "react"
import Button from "../next/button/button"

type CardProps = {
  variant?: "dark" | "light"
  title: string
  description: string
  button?: ComponentProps<typeof Button>
  className?: string
}

export const Card = ({
  variant = "dark",
  title,
  description,
  button,
  className,
}: CardProps) => {
  return (
    <div
      className={cn(
        "col-span-full flex flex-col overflow-clip rounded-xl object-fill sm:col-span-3",
        className,
      )}
    >
      <div
        className={cn(
          "flex flex-1 flex-col p-6",
          variant === "dark" &&
            "bg-[url('../../images/next/tier-developer.png')] bg-cover bg-center bg-no-repeat",
          variant === "light" &&
            "bg-[url('../../images/next/tier-ngo.png')] bg-cover bg-center bg-no-repeat",
        )}
      >
        <h2
          className={cn(
            "text-xl font-medium",
            variant === "dark" && "text-white",
            variant === "light" && "text-gray-900",
          )}
        >
          {title}
        </h2>
        <p
          className={cn(
            "mt-4 max-w-[50ch] text-base ",
            variant === "dark" && "text-gray-200",
            variant === "light" && "dark:text-gray-300 text-gray-600",
          )}
        >
          {description}
        </p>
        {button && <Button className="mt-16" {...button} />}
      </div>
    </div>
  )
}
