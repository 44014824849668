import classNames from "classnames"
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react"

type ItemProps = {
  value?: string
  active?: boolean
  onClick?: () => void
}

const Item = ({
  children,
  value,
  active,
  onClick,
}: PropsWithChildren<ItemProps>) => {
  return (
    <div
      className={classNames(
        active && "bg-gray-900 text-white [&>span]:text-indigo-400",
        !active && "border border-gray-200 bg-white text-gray-900",
        "flex flex-grow cursor-pointer items-center justify-center gap-x-2 rounded p-3 text-sm font-normal sm:w-36",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

type ToggleProps = {
  label?: string
  defaultValue?: string
  children?: ReactElement[]
  onChange?: (value: string) => void
}

export const Toggle = ({
  defaultValue = "",
  label,
  children = [],
  onChange,
}: ToggleProps) => {
  const [active, setActive] = useState("")

  useEffect(() => {
    setActive(defaultValue)
  }, [])

  useEffect(() => {
    onChange?.(active)
  }, [active])

  return (
    <div className="flex w-full flex-col justify-center">
      <span className="text-center font-normal">{label}</span>
      <div className="flex gap-x-1 rounded bg-gray-100 p-1">
        {children.map((i) =>
          React.cloneElement(i, {
            onClick: () => setActive(i.props.value),
            active: active === i.props.value,
            key: i.props.value,
          }),
        )}
      </div>
    </div>
  )
}

Toggle.Item = Item
