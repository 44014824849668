import { Disclosure } from "@headlessui/react"
import cn from "classnames"
import { ComponentProps, useState } from "react"
import Button from "../../freestanding/button/button"
import Container from "../next/container"
import { Range } from "../next/range/range"
import { Card } from "./card"
import { PricingCard } from "./pricing-card"
import { PricingFaq } from "./pricing-faq"
import { PricingTable } from "./pricing-table"
import { Toggle } from "./toggle"
import pricingData from "../../../../page-content/pricing-data.json"

const intlFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
})

export type PricingData = {
  name: string
  price: number
  basePrice: number
  pricePerDau: number
  headline: string
  button: ComponentProps<typeof Button>
  fixed?: boolean
  billing?: string
  pill?: string
  baseDau: number
  description: string
  headlineFeatures: string[]
  featuresHeadline?: string
  features: Record<string, boolean | string | [string, string]>
}

export type Feature = {
  id: string
  name: string
  description: string
}

export type FeatureGroup = {
  name: string
  features: Feature[]
}

const maxDaus = 20000

export const Pricing = () => {
  const [dau, setDau] = useState(100)
  const [billing, setBilling] = useState("month")

  const getPrice = (price: number, baseDau: number, pricePerDau: number) => {
    const overage = dau > baseDau ? dau - baseDau : 0
    const overagePrice = overage * pricePerDau

    let total = price + overagePrice

    if (billing === "year") {
      total = total * 12 - price
    }

    return Math.ceil(total)
  }

  const getBasePrice = (price: number) => {
    if (billing === "year") {
      return price * 11
    }

    return price
  }

  return (
    <div className="relative mx-auto flex w-full flex-col py-6 md:py-24 xl:max-w-[1280px] xl:px-12 2xl:max-w-[1536px] 2xl:p-16">
      <Container
        individualGap="gap-y-4"
        backgroundColor
        className="place-self-center"
      >
        <div className="col-span-12 flex flex-col gap-y-16 place-self-center px-4 sm:max-w-[640px] md:max-w-[768px] md:px-12">
          <div className="mx-auto flex flex-col gap-y-4 text-center">
            <h1 className="text-5xl font-medium text-gray-900">
              Fair pricing that scales with your business
            </h1>
            <p className="text-lg text-gray-600">
              Planet-scale identity infrastructure at down-to-earth costs.
            </p>
          </div>
          <div className="col-span-6 mx-auto sm:col-span-3 md:col-span-5">
            <Toggle
              defaultValue={billing}
              onChange={(value) => setBilling(value)}
            >
              <Toggle.Item value="month">Monthly</Toggle.Item>
              <Toggle.Item value="year">
                Yearly{""}
                <span className="flex flex-nowrap items-center gap-x-1 text-indigo-600">
                  Save 8%
                </span>
              </Toggle.Item>
            </Toggle>
          </div>
        </div>
        <div className="col-span-12 snap-x snap-mandatory overflow-x-auto xl:-ml-0 xl:w-auto">
          <div className="mx-auto grid w-[calc(calc(100vw/3)*8)] grid-flow-col grid-cols-3 grid-rows-[repeat(5,_auto)] gap-x-4 pb-8 sm:w-[calc(calc(100vw/3)*5)] md:w-[calc(calc(628px-16px)*2)] xl:mt-8 xl:w-auto xl:gap-x-8">
            {pricingData.data.slice(1, 4).map((plan, idx) => (
              <PricingCard
                key={idx}
                {...plan}
                button={plan.button as unknown as ComponentProps<typeof Button>}
                description={plan.description}
                features={plan.headlineFeatures}
                name={plan.name}
                featuresHeadline={plan.featuresHeadline}
                billing={plan.fixed ? plan.billing : billing}
                basePrice={
                  typeof plan.basePrice === "string"
                    ? plan.basePrice
                    : getBasePrice(plan.basePrice as number)
                }
                price={
                  dau >= maxDaus
                    ? "Ask us"
                    : typeof plan.price === "string"
                    ? plan.price
                    : getPrice(plan.price, plan.baseDau, plan.pricePerDau)
                }
                className={cn(
                  dau >= maxDaus && idx === 0
                    ? "opacity-25 transition-all"
                    : "",
                  "snap-center",
                )}
              />
            ))}
          </div>
        </div>

        <div className="col-span-full w-full place-self-center px-6 sm:max-w-[640px] md:max-w-[768px] md:px-12">
          <div className="mx-auto mt-8 gap-4 text-center text-base md:w-fit md:flex-row md:text-lg">
            How many users access your application daily{" "}
            <span className="font-medium text-indigo-600">
              {dau.toLocaleString() + (dau === maxDaus ? "+" : "")}
            </span>
          </div>
          <div className="mx-auto mt-8">
            <Range
              defaultValue={dau}
              min={100}
              max={maxDaus}
              step={100}
              edges={["100", `${intlFormatter.format(maxDaus)}+`]}
              tooltipSuffix="Daily Active Users"
              onChange={(e) => setDau(Number(e.target.value))}
            />
          </div>
        </div>

        <div className="col-span-full mt-8 grid w-full grid-cols-1 gap-8  place-self-center px-6 sm:max-w-[640px] sm:grid-cols-2 md:max-w-[768px] md:px-12 lg:max-w-[1024px] lg:px-0 xl:max-w-[1280px] 2xl:max-w-[1536px]">
          <Card
            title="Are you a developer?"
            description="Try out the Ory Network and get your proof of concept up and running in no time. You can run your test and integration systems."
            button={{
              title: "Get started for free",
              to: "https://console.ory.sh/registration",
              variant: "primary-accent",
              fullWidth: false,
              analytics: {
                event: "track",
                category: "call_to_action",
                action: "clicked",
                name: "pricing_free",
              },
            }}
            className="sm:col-span-1"
          />
          <Card
            title="Ory for Non-Profit Orgs"
            description="Building for social and environmental good? Ory’s NPO program helps you expand your impact, not your cost base."
            button={{
              title: "Talk to us",
              to: "https://www.ory.sh/talk-to-us",
              variant: "secondary",
              fullWidth: false,
              analytics: {
                event: "track",
                category: "call_to_action",
                action: "clicked",
                name: "hubspot_contact_form",
              },
            }}
            variant="light"
            className="sm:col-span-1"
          />
        </div>
        <div className="relative col-span-full mt-16">
          <PricingTable />
        </div>
        <div className="relative col-span-full mt-16">
          <PricingFaq />
        </div>
      </Container>
    </div>
  )
}

export default Pricing
