import { Tab, Disclosure } from "@headlessui/react"
import cn from "classnames"
import { CheckCircle } from "phosphor-react"
import { Fragment } from "react"
import Highlight from "../../freestanding/highlight"
import Button from "../next/button/button"
import pricingData from "../../../../page-content/pricing-data.json"

interface Tier {
  name: string
  headline: string
  price: number | string
  [key: string]: any
}

const data: Tier[] = pricingData.data
const features = pricingData.features

export function PricingTable() {
  return (
    <div className="relative col-span-full mx-auto grid gap-y-8 place-self-center px-6 sm:max-w-[640px] md:max-w-[768px] md:px-12 lg:max-w-[1024px] lg:px-0 xl:max-w-[1280px] 2xl:max-w-[1536px]">
      <div className="grid w-auto gap-y-4 md:gap-y-6 lg:flex lg:w-full lg:flex-row lg:justify-between lg:gap-x-8">
        <h2 className="text-4xl font-medium text-gray-900 md:text-5xl lg:max-w-lg lg:flex-1">
          <Highlight>Compare Pricing</Highlight>
        </h2>

        <div className="flex flex-col gap-y-8 lg:max-w-md">
          <p className="dark:text-gray-300 text-lg text-gray-600">
            <Highlight>
              Our pricing tiers are designed to provide flexibility and
              scalability, to meet the level of service that best aligns with
              your requirements.
            </Highlight>
          </p>
        </div>
      </div>
      <PricingTableMobile />
      <PricingTableDesktop />
    </div>
  )
}

const PricingTableDesktop = () => {
  return (
    <div className="hidden gap-y-4 xl:flex xl:flex-col">
      <div className="mt-8 grid grid-cols-6 bg-white py-4 text-sm">
        <div className="py-1"></div>
        {data.map((tier, tIndex) => (
          <div key={tIndex} className="flex flex-col items-start gap-y-4 px-2">
            <h2 className="text-xl font-medium text-indigo-600">{tier.name}</h2>
            <div className="flex items-end text-gray-900">
              {typeof tier.basePrice === "string" ? (
                <div className="text-xl font-medium">{tier.basePrice}</div>
              ) : (
                <>
                  <div className="text-xl font-medium">
                    <span className="mr-0.5">$</span>
                    {tier.basePrice.toLocaleString()}
                  </div>
                  <span className="ml-0.5 text-base text-gray-600">
                    / month
                  </span>
                </>
              )}
            </div>
            <Button
              {...tier.button}
              fullWidth
              className="mb-1 mt-8 text-base font-medium"
            />
          </div>
        ))}
      </div>
      {features.map((featureGroup, fgIndex) => (
        <Disclosure key={fgIndex} defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full border-b border-indigo-200 bg-white last:border-none">
                <div className="flex w-full items-baseline justify-between py-2">
                  <h3 className="text-base font-medium text-gray-900">
                    {featureGroup.name}
                  </h3>
                  <p className="text-indigo-600">{open ? "-" : "+"}</p>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel>
                {featureGroup.features.map((feature, fIndex) => (
                  <div
                    key={fIndex}
                    className="grid min-h-[4rem] grid-cols-6 items-center border-t border-gray-100 text-sm first:border-none"
                  >
                    <div className="flex h-full flex-col justify-center border-r border-gray-100 py-2 pr-2">
                      <p className="leading-tight text-gray-900">
                        {feature.name}
                      </p>
                    </div>
                    {data.map((tier, tIndex) => {
                      const featureValue = tier.features[feature.id]
                      return (
                        <div
                          key={tIndex}
                          className="flex h-full flex-col justify-center border-r border-gray-100 p-2 text-gray-400 last:border-r-0"
                        >
                          {Array.isArray(featureValue) ? (
                            featureValue.map((item, index) => (
                              <p
                                className="leading-normal text-gray-600"
                                key={`${fIndex}-${index}`}
                              >
                                {index === 0 ? (
                                  item
                                ) : (
                                  <span className="text-gray-600">{item}</span>
                                )}
                              </p>
                            ))
                          ) : typeof featureValue === "string" ? (
                            <p className="leading-tight text-gray-600">
                              {featureValue}
                            </p>
                          ) : typeof featureValue === "boolean" ? (
                            featureValue ? (
                              <CheckCircle
                                color="rgb(16, 185, 129)"
                                aria-label=""
                                weight="fill"
                                size={14}
                              />
                            ) : (
                              <p className="leading-tight text-gray-400">×</p>
                            )
                          ) : null}
                        </div>
                      )
                    })}
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}

const PricingTableMobile = () => {
  return (
    <Tab.Group as={"div"} className="grid w-auto xl:hidden">
      <Tab.List className="mb-4 flex space-x-6 overflow-x-auto border-b border-indigo-200 bg-white">
        {data.map((tier, index) => (
          <Tab as={Fragment} key={index}>
            {({ selected }) => (
              <button
                key={index}
                className={cn(
                  selected
                    ? "border-indigo-600 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-indigo-900 hover:text-indigo-900",
                  "whitespace-nowrap rounded-none border-b-2 border-solid py-6 text-sm font-normal",
                )}
                aria-controls={tier.name}
              >
                {tier.name}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels className="w-full">
        {data.map((tier, index) => (
          <Tab.Panel
            key={index}
            className="flex flex-col justify-start gap-y-0"
          >
            <div className="flex flex-col items-start gap-y-4 py-2 pb-4">
              <h2 className="text-xl font-medium text-indigo-600">
                {tier.name}
              </h2>
              <div className="flex items-end text-gray-900">
                {typeof tier.basePrice === "string" ? (
                  <div className="text-xl font-medium">{tier.basePrice}</div>
                ) : (
                  <>
                    <div className="text-xl font-medium">
                      <span className="mr-0.5">$</span>
                      {tier.basePrice.toLocaleString()}
                    </div>
                    <span className="ml-0.5 text-base text-gray-600">
                      / month
                    </span>
                  </>
                )}
              </div>
              <Button
                {...tier.button}
                fullWidth
                className="mb-1 text-base font-medium"
              />
            </div>
            {features.map((featureGroup, fgIndex) => (
              <Disclosure key={fgIndex} defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="border-b border-indigo-200 bg-white last:border-none">
                      <div className="flex w-full items-baseline justify-between py-2 ">
                        <h3 className="text-sm font-medium text-gray-900">
                          {featureGroup.name}
                        </h3>
                        <p className="text-indigo-600">{open ? "-" : "+"}</p>
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <div className="flex flex-col pb-8">
                        {featureGroup.features.map((feature, fIndex) => {
                          const featureKey = feature.id
                          const featureValue = tier.features[featureKey]

                          return (
                            <div
                              key={fIndex}
                              className="flex flex-row items-center border-t border-gray-100 text-sm first:border-none"
                            >
                              <div className="h-full w-1/2 border-r border-gray-200 py-2 pr-2">
                                <p className="text-gray-900">{feature.name}</p>
                              </div>
                              <div className="flex h-full w-1/2 flex-col items-center justify-center py-1 text-center text-gray-400">
                                {Array.isArray(featureValue) ? (
                                  featureValue.map((item, index) => (
                                    <p
                                      className="text-gray-600"
                                      key={`${fIndex}-${index}`}
                                    >
                                      {index === 0 ? (
                                        item
                                      ) : (
                                        <span className="text-gray-600">
                                          {item}
                                        </span>
                                      )}
                                    </p>
                                  ))
                                ) : typeof featureValue === "string" ? (
                                  <p className="text-gray-600">
                                    {featureValue}
                                  </p>
                                ) : typeof featureValue === "boolean" ? (
                                  featureValue ? (
                                    <CheckCircle
                                      color="rgb(16, 185, 129)"
                                      aria-label=""
                                      weight="fill"
                                      size={14}
                                    />
                                  ) : (
                                    <p className="text-gray-600">×</p>
                                  )
                                ) : null}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}
