import cn from "classnames"
import { ArrowLeft, Check } from "phosphor-react"
import { ComponentProps } from "react"
import Button from "../next/button/button"

type PricingCardProps = {
  name: string
  price: number | string
  basePrice: number | string
  description: string
  pill?: string
  billing: string
  featuresHeadline?: string
  features: string[]
  button: ComponentProps<typeof Button>
  className?: string
}

export const PricingCard = ({
  name = "Essentials",
  price = 29,
  basePrice = 29,
  pill,
  description,
  button,
  billing,
  features = [],
  featuresHeadline,
  className,
}: PricingCardProps) => {
  const borderColor = pill ? "border-indigo-600" : "border-gray-200"
  const bgColor = pill ? "bg-indigo-50" : "bg-white"

  return (
    <>
      <div
        className={cn(
          className,
          "border-1 row-span-1 mt-8 flex rounded-t-lg border-l border-r border-t px-6 pb-6 pt-6 xl:mt-0",
          borderColor,
          bgColor,
        )}
      >
        {pill && (
          <div className="mb-4 flex items-center rounded-full bg-gray-900 px-3 py-1 text-sm text-white">
            <span>{pill}</span>
          </div>
        )}
      </div>
      <div
        className={cn(
          className,
          "dark:bg-gray-900 row-span-1 flex flex-col border-x px-6",
          borderColor,
          bgColor,
        )}
      >
        <h2 className="text-xl font-medium text-indigo-600">{name}</h2>
        <span className="mt-2 text-gray-600">
          {typeof basePrice === "string" ? <>&nbsp;</> : "starting from"}
        </span>
        <div className="mt-2 flex items-end text-gray-900">
          {typeof basePrice === "string" ? (
            <div className="text-4xl font-medium">{basePrice}</div>
          ) : (
            <>
              <div className="text-4xl font-medium">
                <span className="mr-0.5">$</span>
                {basePrice.toLocaleString()}
              </div>
              <span className="ml-0.5 text-base text-gray-600">
                / {billing}
              </span>
            </>
          )}
        </div>
        <p className="mt-4 text-base leading-normal text-gray-600">
          {description}
        </p>
      </div>
      <div
        className={cn(
          className,
          "row-span-1 border-x px-6",
          borderColor,
          bgColor,
        )}
      >
        <Button
          {...button}
          fullWidth
          className="mb-1 mt-8 text-base font-medium"
        />
      </div>
      <div
        className={cn(
          className,
          "row-span-1 border-x p-6",
          borderColor,
          bgColor,
        )}
      >
        <ul className="ml-6">
          <li className="relative mb-5 flex items-center text-sm text-gray-900">
            <div className="absolute -left-[24px] h-5 w-5">
              {featuresHeadline && <ArrowLeft aria-label="" size={18} />}
            </div>
            {featuresHeadline || "Including:"}
          </li>
          {features.map((i) => (
            <li
              key={i}
              className="relative mt-1 flex items-start text-sm text-gray-600"
            >
              <div className="absolute -left-[24px] mt-1.5">
                <Check
                  color="rgb(16, 185, 129)"
                  aria-label=""
                  weight="bold"
                  size={14}
                />
              </div>
              <div className="my-1">{i}</div>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={cn(
          className,
          "row-span-1 flex flex-col gap-y-4 rounded-b-lg border-x border-b px-6 pb-12 pt-6",
          borderColor,
          bgColor,
        )}
      >
        <hr className={cn("m-0 w-full bg-gray-200", borderColor, bgColor)} />
        <span className="mt-2 text-sm text-gray-600">total invoice</span>
        <div className="mt-2 flex items-end text-gray-900">
          {typeof price === "string" ? (
            <div className="text-2xl font-medium">{price}</div>
          ) : (
            <>
              <div className="text-2xl font-medium">
                <span className="mr-0.5">$</span>
                {price.toLocaleString()}
              </div>
              <span className="ml-0.5 text-base text-gray-600">
                / {billing}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  )
}
